<template>
  <el-card
    shadow="never">
    <el-row :gutter="20">
      <el-col :span="4" style="text-align: left;">
        <el-button
          type="primary"
          @click="handleAddNews">
          新增
        </el-button>
      </el-col>
      <el-col :span="20">
        <news-search
          :keyword="keyword"
          :status="status"
          @handleQuery="handleQuery"
          @handleRefresh="handleRefresh" />
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import NewsSearch from './NewsSearch'

export default {
  components: {
    NewsSearch
  },
  props: {
    keyword: {
      type: String,
      require: true
    },
    status: {
      type: String,
      require: true
    }
  },
  computed: {
    cardBodyStyle: {
      get () {
        return {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'start'
        }
      }
    }
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.$emit('handleRefresh')
    },
    handleAddNews () {
      // this.$emit('handlAddVisible', true)
      this.$router.push('News/add')
    }
  }
}
</script>
<style lang="scss" scoped>
.left-container{margin-right: 100px;}
</style>
