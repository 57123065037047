<template>
  <div class="search-container">
    <el-form :inline="true" label-width="80px">
      <el-form-item>
        <el-input
          v-model="searchInput"
          placeholder="请输入关键字"
          suffix-icon="el-icon-search" />
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchVisible" placeholder="状态">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleRefresh">
          查询
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: '全部'
      },
      {
        value: 1,
        label: '上架'
      },
      {
        value: 0,
        label: '下架'
      }],
      selectValue: ''
    }
  },
  props: {
    keyword: {
      type: String,
      require: true
    },
    status: {
      type: String,
      require: true
    }
  },
  computed: {
    searchInput: {
      set (str) {
        this.handleQuery({
          keyword: str
        })
      },
      get () {
        return this.keyword
      }
    },
    searchVisible: {
      set (str) {
        this.selectValue = str
        this.handleQuery({
          is_visible: str
        })
      },
      get () {
        return this.status
      }
    }
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.handleQuery({
        page: 1
      })
      this.$emit('handleRefresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.el-form-item{margin-bottom: 0!important;}
</style>
